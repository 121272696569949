import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  max-width: ${(props) => props.theme.containerWidths.medium};
  margin-left: auto;
  margin-right: auto;

  .padded-section {
    p {
      + p {
        margin-top: 8px;
      }

      + div {
        margin-top: 22px;
      }
    }
  }

  .gatsby-image-wrapper {
    margin-top: -10px;
    margin-bottom: 20px;
  }

  @media screen and (min-width: ${(props) => props.theme.responsive.medium}) {
    section {
      + .padded-section {
        padding-top: 0;
      }
    }
  }
`

const Events = () => {

  return (
    <Wrapper className="wrap">
      <section className="section">
        <h3 className="headline--reunion center-align">Love is Never Wasted Foundation Events</h3>
        <h4>Over the Rainbow Foundation Partnership - Atlanta Angels</h4>
        <p>
          We’re thrilled to partner with the Atlanta Angels for this year’s Reunion. Their mission is to walk alongside children, youth, and
          families in the foster care community by offering consistent support through intentional giving, relationship building, and mentorship.
          Atlanta Angel’s provides resources that meet the real needs of the child and their entire family. They devote time and energy to fostering
          healthy relationships that promote healing through connections and interpersonal bonding. Lastly, they empower youth to be prepared for
          independent living, accomplish tailored goals, build connections, and reach their fullest potential. Keep reading for ways you can
          participate in this year’s Reunion Foundation efforts.
        </p>
      </section>
      <section className="padded-section">
        <h3 className="headline--reunion center-align">Parties</h3>
        <p className="title">
          <span>Wish Upon a Star</span>
        </p>
        <p>
          <span className="semi-bold-weight">What:</span>
          {' '}Once you’ve settled in at Over the Rainbow, stop by our Foundation Pop-Up to learn more about how you can foster love for the local
          foster care community of Atlanta. Atlanta Angels is an amazing organization that supports children, youth, and families in the foster care
          community.
        </p>
        <p>
          <span className="semi-bold-weight">When:</span>
          {' '}Friday + Saturday, September 20 + 21, 12:00pm - 1:30pm
        </p>
        <p>
          <span className="semi-bold-weight">Where:</span>
          {' '}First Floor
        </p>
      </section>

      <section className="padded-section">
        <p className="title">
          <span>Light the Way Dinner (ticketed event)</span>
        </p>
        <p>
          <span className="semi-bold-weight">What:</span>
          {' '}Artists can purchase a $50 USD ticket to the foster care charity dinner taking place before the Award Ceremony (General Session II).
          All proceeds will be donated to the Atlanta Angels. <span style={{ fontStyle: 'italic' }}>This event will be capped at 800 tickets.</span>
        </p>
        <p>
          <span className="semi-bold-weight">When:</span>
          {' '}Friday, September 20, 6:00pm - 7:30pm
        </p>
        <p>
          <span className="semi-bold-weight">Where:</span>
          {' '}Triumph Ballroom
        </p>
      </section>
    </Wrapper>
  )
}

export default Events
